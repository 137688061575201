import React from "react";
import Giai1 from "../assets/images/voucher.png";
import Giai2 from "../assets/images/luckybox.png";
import Giai3 from "../assets/images/game.png";
import Mascot from "../assets/images/mascot-left.png";
import { isMobile } from "react-device-detect";
import { motion, Variants } from "framer-motion";

export default function Reward() {
  const cardVariants: Variants = {
    offscreen: {
      y: -30,
    },
    onscreen: {
      y: 0,
      rotate: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  return (
    <>
      {isMobile ? (
        <motion.section
          className={isMobile ? "relative flex items-center md:mt-[-200px] mt-[20px] md:justify-between" : "relative flex items-center md:mt-20 mt-10 md:justify-between"}
          initial="offscreen"
          whileInView={{
            opacity: 1,
            x: 0,
            y: -100,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true, amount: 0.8 }}
        >
          <motion.div className="logo-left " variants={cardVariants}>
            <div className="logo-left flex-none ">
              <img src={Mascot} alt="GetGo" className="w-[100px] h-[auto]" />
            </div>
          </motion.div>
          <motion.div className="" variants={cardVariants}>
            <img src={Giai1} alt="Giải nhất" className="w-[100px] h-[auto]" />
          </motion.div>
          <motion.div className="" variants={cardVariants}>
            <img src={Giai2} alt="Giải nhì" className="w-[100px] h-[auto]" />
          </motion.div>
          <motion.div className="" variants={cardVariants}>
            <img src={Giai3} alt="Giải ba" className="w-[100px] h-[auto]" />
          </motion.div>
        </motion.section>
      ) : (
        <motion.section
          className="relative min-[1250px]:grid max-[1250px]:flex flex-nowrap grid-cols-7 max-[1150px]:grid-cols-5 gap-4 mt-10  mx-auto xl:w-[1200px] lg:w-[1000px]"
          initial={{
            opacity: 1,
            x: 0,
            y: -30,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            y: -140,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true, amount: 0.8 }}
        >
          <motion.div
            className="logo-left col-span-1 max-[1150px]:col-span-1 "
            variants={cardVariants}
            initial={{
              opacity: 1,
              x: -30,
              y: 0,
            }}
            whileInView={{
              opacity: 1,
              x: 250,
              y: 10,
              transition: {
                duration: 1,
              },
            }}
          >
            <div className="logo-left">
              <img
                src={Mascot}
                alt="GetGo"
                className="min-[1250px]:w-[180px] md:w-[140px]"
              />
            </div>
          </motion.div>
          <motion.div
            className="logo-left col-span-2 max-[1150px]:col-span-2"
            variants={cardVariants}
          >
           
          </motion.div>
          <motion.div
            className="logo-left col-span-1 max-[1150px]:col-span-1 "
            variants={cardVariants}
            initial={{
              opacity: 1,
              x: -30,
              y: 0,
            }}
            whileInView={{
              opacity: 1,
              x: -30,
              y: 30,
              transition: {
                duration: 1,
              },
            }}
          >
            <div className="logo-left">
              <img
                src={Giai1}
                alt="Giải nhất"
                className="min-[1250px]:w-[180px] md:w-[140px]"
              />
            </div>
          </motion.div>
          <motion.div
            className="logo-left col-span-1 max-[1150px]:col-span-1 "
            variants={cardVariants}
          >
            <div className="logo-left">
              <img
                src={Giai2}
                alt="Giải nhì"
                className="min-[1250px]:w-[180px] md:w-[140px]"
              />
            </div>
          </motion.div>
          <motion.div
            className="logo-left col-span-1 max-[1150px]:col-span-1 "
            variants={cardVariants}
          >
            <div className="logo-left">
              <img
                src={Giai3}
                alt="Giải ba"
                className="min-[1250px]:w-[180px] md:w-[140px]"
              />
            </div>
          </motion.div>
        </motion.section>
      )}
    </>
  );
}

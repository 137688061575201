import React from "react";

export default function Roles() {
  return (
    <>
      <div className="my-[15px]">
        <h2 className="text-lg text-white  uppercase font-extrabold text-center">
          Thể lệ
        </h2>
        <ul className="list-disc text-white text-base pl-[20px]">
          <li>
            Áp dụng cho khách hàng đã mua hàng tại Gét Gô, có thông tin đơn hàng hợp lệ.
          </li>
          <li>
            Khách hàng khi trúng giải cần phải cung cấp thông tin cá nhân để xác
            nhận trúng giải gồm: Số điện thoại; Mã giao dịch; CMND/CCCD; Đơn
            hàng hoặc hình ảnh đơn hàng khớp với thông tin đã đăng ký.
          </li>
        </ul>
      </div>
      <div className="my-[15px]">
        <h2 className="text-lg text-white  uppercase font-extrabold text-center">
          Lưu ý
        </h2>
        <ul className="list-disc text-white text-base pl-[20px]">
          <li>
            Khách hàng phải giữ lại đơn hàng đã giao dịch để đối soát khi nhận
            giải thưởng.
          </li>
          <li>
            Mã quay số hợp lệ là mã Đơn hàng với số điện thoại
            thành viên đã mua giao dịch.
          </li>
          <li>
            Người thắng giải hợp lệ là Người trình được đơn hàng
            trùng khớp với mã được chọn sau khi quay số ngẫu nhiên.
          </li>
          <li>
            Gét Gô có quyền huỷ kết quả nếu phát hiện mã quay số trúng
            thưởng không hợp lệ và tiến hành quay số lại để tìm người thắng giải
            hợp lệ.
          </li>
          <li>
            Trong mọi trường hợp, quyết định của Gét Gô là quyết định
            cuối cùng.
          </li>
        </ul>
      </div>
      <div>
        <h2 className="text-lg text-white  uppercase font-extrabold text-center">
          Các quy định khác
        </h2>
        <ul className=" text-white text-base">
          <li>
            ✔ Người tham gia cần phải đọc và hiểu rõ thể lệ của chương trình,
            đồng ý và tuân theo quy định khi tham gia chương trình.
          </li>
          <li>
            ✔ Người tham gia cam kết các thông tin cung cấp cho chương trình là
            chính xác và hợp pháp.
          </li>
          <li>
            ✔ Nếu phát hiện người tham gia gian lận hoặc có dấu hiệu gian lận,
            BTC có quyền hủy quyền tham gia của người tham gia mà không cần
            thông báo trước.
          </li>
          <li>
            ✔ BTC sẽ không chịu trách nhiệm đối với việc giải thưởng bị thất lạc
            do thông tin người tham gia cung cấp không chính xác, thiếu thông
            tin hoặc không liên lạc được với người tham gia khi nhân viên trao
            giải thưởng đến giao quà (tối đa 03 lần liên hệ).
          </li>
          <li>
            ✔ Công ty Cổ TNHH DeHub International Việt Nam chịu toàn bộ trách nhiệm về tính
            chính xác của bằng chứng xác định trúng thưởng và đưa bằng chứng xác
            định trúng thưởng vào lưu thông trong chương trình.
          </li>
          <li>
            ✔ Trường hợp bất khả kháng do thiên tai, lũ lụt, sét đánh, khủng bố
            tấn công máy chủ làm thất thoát dữ liệu đăng ký của người tham gia,
            BTC sẽ giữ quyền quyết định thay đổi hoặc hủy bỏ chương trình và
            thông báo với người tham gia trong thời gian sớm nhất.
          </li>
          <li>
            ✔ Người tham gia đồng ý cung cấp thông tin cá nhân (“Thông tin cá
            nhân”) cho BTC. Để phục vụ cho hoạt động của Bên tổ chức chương
            trình, người tham gia đồng ý và xác nhận là:{" "}
            <ul className="pl-[20px]">
              <li>
                (i) Bên tổ chức chương trình được quyền thu thập, lưu trữ, xử
                lý, sử dụng, mã hoá và/hoặc chia sẻ Thông tin cá nhân cho các
                công ty trong cùng tập đoàn và/hoặc các bên thứ ba do Bên tổ
                chức chương trình chỉ định.
              </li>
              <li>
                (ii) Khi được Bên Tổ chức chương trình cho phép, các công ty
                trong cùng tập đoàn và các bên thứ ba do Bên tổ chức chương
                trình chỉ định được quyền thu thập, lưu trữ, xử lý, sử dụng,
                chuyển giao, và/hoặc mã hoá Thông tin cá nhân của Người tham
                gia.
              </li>
            </ul>
          </li>
          <li>
            ✔ Trong trường hợp phát sinh tranh chấp, khiếu nại liên quan đến
            chương trình, Gét Gô sẽ trực tiếp giải quyết và quyết định
            của BTC sẽ là kết quả cuối cùng.
          </li>
        </ul>
      </div>
    </>
  );
}

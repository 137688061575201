// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.swal2-popup {
  grid-column: 1!important;
  grid-row: 1!important;
  place-self: center center;
  /* width: 90%; */
  margin-left: -12%;
}

.wheel-mobile {
  max-height: 350px;
  width: 300px;
  margin: 0px auto 0px;
  margin-top: -120px;
  margin-bottom: 70px;
}

.wheel-mobile canvas{
  /* zoom: 0.75; */
  width: 500px;
  max-width: 500px;
}

.form-mobile {
  width: 90%;
  margin-left: 5%;
}

#root {
  /* background-image: linear-gradient(#cc2f06, #fb7b0d, #f7dbc6); */
  background-image: linear-gradient(#f7dbc6, #fb7b0d, #cc2f06);
}

.bg-page {
  /* background-image: url('/src/assets/images/background.webp'); */

  background-size: cover;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;EACrB,yBAAyB;EACzB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,oBAAoB;EACpB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,kEAAkE;EAClE,4DAA4D;AAC9D;;AAEA;EACE,iEAAiE;;EAEjE,sBAAsB;AACxB;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n.swal2-popup {\n  grid-column: 1!important;\n  grid-row: 1!important;\n  place-self: center center;\n  /* width: 90%; */\n  margin-left: -12%;\n}\n\n.wheel-mobile {\n  max-height: 350px;\n  width: 300px;\n  margin: 0px auto 0px;\n  margin-top: -120px;\n  margin-bottom: 70px;\n}\n\n.wheel-mobile canvas{\n  /* zoom: 0.75; */\n  width: 500px;\n  max-width: 500px;\n}\n\n.form-mobile {\n  width: 90%;\n  margin-left: 5%;\n}\n\n#root {\n  /* background-image: linear-gradient(#cc2f06, #fb7b0d, #f7dbc6); */\n  background-image: linear-gradient(#f7dbc6, #fb7b0d, #cc2f06);\n}\n\n.bg-page {\n  /* background-image: url('/src/assets/images/background.webp'); */\n\n  background-size: cover;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

export const DataCinemas = [
    // {
    //   "id": "fb233b0f-edb4-4eb1-ade8-7f8b83ab2457",
    //   "name": "Galaxy Nguyễn Du",
    //   "slug": "galaxy-nguyen-du",
    //   "latitude": "10.773390",
    //   "longitude": "106.693290",
    //   "address": "116 Nguyễn Du, Quận 1, Tp.HCM",
    //   "phone": "1900 2224",
    //   "cityId": "599535ea-1ea2-4393-9b5a-3ba3a807f363",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-nguyen-du-3_1698051874807.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-nguyen-du-1_1698051870157.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-nguyen-du-1_1698051240852.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-nguyen-du-4_1698051246666.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-nguyen-du-2_1698051251352.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-nguyen-du-3_1698051255427.jpg"
    //   ],
    //   "reward": true,
    //   "order": 1
    // },
    // {
    //   "id": "6bcd3542-1bcf-4d2c-8ab4-63cac086f80a",
    //   "name": "Galaxy Sala",
    //   "slug": "galaxy-sala",
    //   "latitude": "10.771616829729412",
    //   "longitude": "106.72178724636747",
    //   "address": "Tầng 3, Thiso Mall Sala, 10 Mai Chí Thọ, Phường Thủ Thiêm, Thành phố Thủ Đức",
    //   "phone": "19002224",
    //   "cityId": "599535ea-1ea2-4393-9b5a-3ba3a807f363",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/2023/12/12/glx-sala_1702353310946.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2023/12/12/galaxy-sala_1702353228107.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2023/12/25/1_1703500551418.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/12/25/2_1703500555704.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/12/25/3_1703500560520.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/12/25/4_1703500565605.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/12/25/5_1703500570351.jpg"
    //   ],
    //   "reward": true,
    //   "order": 3
    // },
    // {
    //   "id": "80f80767-6c5a-44b0-8ca5-fe62c40aa4c4",
    //   "name": "Galaxy Tân Bình",
    //   "slug": "galaxy-tan-binh",
    //   "latitude": "10.790628",
    //   "longitude": "106.640669",
    //   "address": "246 Nguyễn Hồng Đào, Q.TB, Tp.HCM",
    //   "phone": "1900 2224",
    //   "cityId": "599535ea-1ea2-4393-9b5a-3ba3a807f363",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/2023/11/22/1800_1700639928243.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2023/11/22/1200_1700639837511.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2019/5/6/rapgiave-hinhrap-tan-binh-1_1557134148145.jpg",
    //     "https://cdn.galaxycine.vn/media/2019/5/6/rapgiave-hinhrap-tan-binh-2_1557134155796.jpg",
    //     "https://cdn.galaxycine.vn/media/2019/5/6/rapgiave-hinhrap-tan-binh-3_1557133920863.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/11/22/1_1700639852832.jpg"
    //   ],
    //   "reward": true,
    //   "order": 3
    // },
    {
      "id": "fe18db21-cdee-44ba-90c3-f2e3ac6c8320",
      "name": "Galaxy Kinh Dương Vương",
      "slug": "galaxy-kinh-duong-vuong",
      "latitude": "10.750501",
      "longitude": "106.628085",
      "address": "718bis Kinh Dương Vương, Q6, TpHCM.",
      "phone": "1900 2224",
      "cityId": "599535ea-1ea2-4393-9b5a-3ba3a807f363",
      "imageLandscape": "https://cdn.galaxycine.vn/media/2023/11/22/1800_1700640830550.jpg",
      "imagePortrait": "https://cdn.galaxycine.vn/media/2023/11/22/1200_1700640800802.jpg",
      "imageUrls": [
        "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-kinh-duong-vuong--1_1698741232307.jpg",
        "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-kinh-duong-vuong--2_1698741236990.jpg",
        "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-kinh-duong-vuong--3_1698741240596.jpg",
        "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-kinh-duong-vuong--4_1698741245932.jpg"
      ],
      "reward": true,
      "order": 4
    },
    {
      "id": "13ca0671-3301-4341-ab39-15893aec02f6",
      "name": "Galaxy Quang Trung",
      "slug": "galaxy-quang-trung",
      "latitude": "10.835079",
      "longitude": "106.662213",
      "address": "Lầu 3, TTTM CoopMart Foodcosa số 304A, Quang Trung, P.11, Q. Gò Vấp, Tp.HCM",
      "phone": "1900 2224",
      "cityId": "599535ea-1ea2-4393-9b5a-3ba3a807f363",
      "imageLandscape": "https://cdn.galaxycine.vn/media/2023/11/22/1800_1700640689081.jpg",
      "imagePortrait": "https://cdn.galaxycine.vn/media/2023/11/22/1200_1700640684805.jpg",
      "imageUrls": [
        "https://cdn.galaxycine.vn/media/2019/5/6/rapgiave-hinhrap-quang-trung-1_1557133544324.jpg",
        "https://cdn.galaxycine.vn/media/2019/5/6/rapgiave-hinhrap-quang-trung-2_1557133786398.jpg",
        "https://cdn.galaxycine.vn/media/2019/5/6/rapgiave-hinhrap-quang-trung-3_1557133790242.jpg",
        "https://cdn.galaxycine.vn/media/2023/11/22/2_1700640675099.jpg"
      ],
      "reward": true,
      "order": 5
    },
    // {
    //   "id": "0f95ca57-3707-4e69-bf01-c15993afd3ea",
    //   "name": "Galaxy Bến Tre",
    //   "slug": "galaxy-ben-tre",
    //   "latitude": "10.241451",
    //   "longitude": "106.376743",
    //   "address": "Lầu 1, TTTM Sense City 26A Trần Quốc Tuấn, Phường 4, TP. Bến Tre",
    //   "phone": "1900 2224",
    //   "cityId": "3504c4df-cc0f-4356-a934-260cf5e9ca32",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/4/./4_12.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-ben-tre-3_1698053831044.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-ben-tre-1_1698053844082.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-ben-tre-2_1698053849001.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-ben-tre-4_1698053853519.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-ben-tre-5_1698053857288.jpg"
    //   ],
    //   "reward": true,
    //   "order": 6
    // },
    // {
    //   "id": "5e0c771c-55b2-431c-a368-b8bd1d815e3d",
    //   "name": "Galaxy Mipec Long Biên",
    //   "slug": "galaxy-mipec-long-bien",
    //   "latitude": "21.045536",
    //   "longitude": "105.865776",
    //   "address": "Tầng 6, TTTM Mipec Long Biên, Số 2, Phố Long Biên 2, Ngọc Lâm, Long Biên, Hà Nội",
    //   "phone": "1900 2224",
    //   "cityId": "f4bf5f53-4e80-40c8-b1e0-f11ffa9a636a",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/2023/11/22/1800_1700639234996.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2023/11/22/1200_1700639230326.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-mipec-long-bien-1_1698745465411.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-mipec-long-bien-4_1698745470938.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-mipec-long-bien-2_1698745476496.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-mipec-long-bien-3_1698745488907.jpg"
    //   ],
    //   "reward": true,
    //   "order": 7
    // },
    // {
    //   "id": "3a9fe5b5-0f63-4889-aaae-6c1b76d7050d",
    //   "name": "Galaxy Đà Nẵng",
    //   "slug": "galaxy-da-nang",
    //   "latitude": "16.066673",
    //   "longitude": "108.186528",
    //   "address": "Tầng 3, TTTM Coop Mart, 478 Điện Biên Phủ, Quận Thanh Khê, Đà Nẵng",
    //   "phone": "1900 2224",
    //   "cityId": "48def6c3-5254-4ece-b63c-e5524fda1296",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/1/./1_172.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-da-nang_1698052408891.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-da-nang-1_1698052206857.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-da-nang-3_1698052210667.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-da-nang-4_1698052214666.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-da-nang-5_1698052220835.jpg"
    //   ],
    //   "reward": true,
    //   "order": 8
    // },
    // {
    //   "id": "0a7ad4dd-1a39-46d7-9ec1-aaaf1d17cb55",
    //   "name": "Galaxy Cà Mau",
    //   "slug": "galaxy-ca-mau",
    //   "latitude": "9.177642",
    //   "longitude": "105.154259",
    //   "address": "Lầu 2, TTTM Sense City, số 9, Trần Hưng Đạo, P.5, Tp. Cà Mau",
    //   "phone": "1900 2224",
    //   "cityId": "477b975e-caac-4149-8fcc-a10fbde8df84",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/3/./3_250.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-ca-mau_1698051664047.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-ca-mau-1_1698051537159.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-ca-mau-2_1698051541015.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-ca-mau-3_1698051544914.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-ca-mau-4_1698051549441.jpg"
    //   ],
    //   "reward": false,
    //   "order": 9
    // },
    // {
    //   "id": "b4937159-1a8e-43c1-b6eb-e92e13352e2e",
    //   "name": "Galaxy Trung Chánh",
    //   "slug": "galaxy-trung-chanh",
    //   "latitude": "10.855400",
    //   "longitude": "106.607965",
    //   "address": "TTVH Quận 12, Số 09 Quốc Lộ 22, P. Trung Mỹ Tây, Quận 12",
    //   "phone": "1900 2224",
    //   "cityId": "599535ea-1ea2-4393-9b5a-3ba3a807f363",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/2023/11/22/1800_1700638861544.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2023/11/22/1200_1700638856645.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2019/5/6/rapgiave-hinhrap-tc-01_1557133665388.jpg",
    //     "https://cdn.galaxycine.vn/media/2019/5/6/rapgiave-hinhrap-tc-02_1557133669536.jpg",
    //     "https://cdn.galaxycine.vn/media/2019/5/6/rapgiave-hinhrap-tc-03_1557133911768.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/11/22/2_1700638871113.jpg"
    //   ],
    //   "reward": true,
    //   "order": 10
    // },
    {
      "id": "12f89a54-57df-4dc7-9633-56eb828b2956",
      "name": "Galaxy Huỳnh Tấn Phát",
      "slug": "galaxy-huynh-tan-phat",
      "latitude": "10.712519",
      "longitude": "106.736914",
      "address": "Lầu 2, TTTM Coopmart, số 1362 Huỳnh Tấn Phát, khu phố 1, Phường Phú Mỹ, Quận 7, Tp.Hồ Chí Minh, Việt Nam.",
      "phone": "19002224 ",
      "cityId": "599535ea-1ea2-4393-9b5a-3ba3a807f363",
      "imageLandscape": "https://cdn.galaxycine.vn/media/d/s/dsc03732.jpg",
      "imagePortrait": "https://cdn.galaxycine.vn/media/2023/11/22/1200_1700638489647.jpg",
      "imageUrls": [
        "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-hunh-tan-phat-1_1698739170568.jpg",
        "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-hunh-tan-phat-2_1698739175395.jpg",
        "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-hunh-tan-phat-3_1698739179653.jpg",
        "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-hunh-tan-phat-4_1698739183585.jpg"
      ],
      "reward": true,
      "order": 11
    },
    // {
    //   "id": "f3ba1590-5904-4ce9-8178-e3c6a9608df4",
    //   "name": "Galaxy Vinh",
    //   "slug": "galaxy-vinh",
    //   "latitude": "18.676532",
    //   "longitude": "105.677077",
    //   "address": "Lầu 5, Trung tâm Giải Trí City HUB – số 1 Lê Hồng Phong, thành phố Vinh",
    //   "phone": "1900 2224",
    //   "cityId": "6c1d6ebc-dfc9-47ca-b0e9-2be7766f68d4",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/2023/11/22/1800_1700638324348.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2023/11/22/1200_1700638319306.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2021/3/3/dsc00687-2_1614762420979.jpg",
    //     "https://cdn.galaxycine.vn/media/2021/3/3/hinh-adapt_1614762383198.jpg",
    //     "https://cdn.galaxycine.vn/media/2021/3/3/hinh-adapt-05-_1614762427773.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/11/22/1_1700638336647.jpg"
    //   ],
    //   "reward": true,
    //   "order": 12
    // },
    // {
    //   "id": "e4c4d2ec-d494-4054-86df-1011bf53f9b6",
    //   "name": "Galaxy Hải Phòng",
    //   "slug": "galaxy-hai-phong",
    //   "latitude": "20.857549",
    //   "longitude": "106.686272",
    //   "address": "Lầu 7, TTTM Nguyễn Kim - Sài Gòn Mall, số 104 Lương Khánh Thiện.",
    //   "phone": "19002224",
    //   "cityId": "29ca9635-7ff0-44bf-8793-c97acba3f57a",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-hai-phong-4_1698055519119.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-hai-phong-6_1698055513353.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-hai-phong-1_1698055533523.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-hai-phong-2_1698055537815.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-hai-phong-3_1698055542021.jpg"
    //   ],
    //   "reward": true,
    //   "order": 13
    // },
    {
      "id": "bd298261-58ea-4e87-bb27-337be87ba00c",
      "name": "Galaxy Nguyễn Văn Quá",
      "slug": "galaxy-nguyen-van-qua",
      "latitude": "10.841549",
      "longitude": "106.630018",
      "address": "119B Nguyễn Văn Quá, Phường Đông Hưng Thuận, Quận 12",
      "phone": "19002224",
      "cityId": "599535ea-1ea2-4393-9b5a-3ba3a807f363",
      "imageLandscape": "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-nguyen-van-qua_1698747232088.jpg",
      "imagePortrait": "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-nguyen-van-qua-1_1698747088518.jpg",
      "imageUrls": [
        "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-nguyen-van-qua-2_1698747051873.jpg",
        "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-nguyen-van-qua-3_1698747056362.jpg",
        "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-nguyen-van-qua-4_1698747063538.jpg",
        "https://cdn.galaxycine.vn/media/2023/10/31/galaxy-nguyen-van-qua-5_1698747068418.jpg"
      ],
      "reward": true,
      "order": 14
    },
    // {
    //   "id": "59eb32e2-a35e-4af5-89a2-90debbaf9767",
    //   "name": "Galaxy Buôn Ma Thuột",
    //   "slug": "galaxy-buon-ma-thuot",
    //   "latitude": "12.692027",
    //   "longitude": "108.062238",
    //   "address": "Tầng trệt, TTTM Coop Mart Buôn Ma Thuột, số 71 Nguyễn Tất Thành, Phường Tân An, Tp. Buôn Ma Thuột, Tỉnh Đắk Lắk, Việt Nam",
    //   "phone": "19002224",
    //   "cityId": "95ea07fe-5999-4030-bb5f-abe0440d96f0",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/2019/7/25/galaxy45_1564069246883.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2019/7/25/galaxy42_1564069263592.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-buon-ma-thuot-6_1698054835790.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-buon-ma-thuot-8_1698054847475.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-buon-ma-thuot-3_1698054848335.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/23/galaxy-buon-ma-thuot-4_1698054854910.jpg"
    //   ],
    //   "reward": true,
    //   "order": 16
    // },
    // {
    //   "id": "5a102aff-459d-4613-906d-5a8a4396a74f",
    //   "name": "Galaxy Long Xuyên",
    //   "slug": "galaxy-long-xuyen",
    //   "latitude": "10.384043140818825",
    //   "longitude": "105.43676839948661",
    //   "address": "Tầng 1, TTTM Nguyễn Kim, số 01 Trần Hưng Đạo, Phường Mỹ Bình, Thành phố Long Xuyên",
    //   "phone": "19002224",
    //   "cityId": "356d6f3d-5cb6-4392-9abf-84aae78c6573",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/2019/12/27/79592830-139480534153750-6373489647720333312-o_1577418493102.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2019/12/27/79954387-139481084153695-3246954220183617536-o_1577418431929.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2023/11/22/1_1700637844296.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/11/22/2_1700637848517.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/11/22/3_1700637852529.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/11/22/4_1700637856499.jpg"
    //   ],
    //   "reward": true,
    //   "order": 17
    // },
    // {
    //   "id": "d1b07444-41e4-46b4-99c6-444e97518624",
    //   "name": "Galaxy Co.opXtra Linh Trung",
    //   "slug": "galaxy-linh-trung",
    //   "latitude": "10.874162",
    //   "longitude": "106.776963",
    //   "address": "Tầng trệt, TTTM Co.opXtra Linh Trung, số 934 Quốc Lộ 1A, Phường Linh Trung, Quận Thủ Đức, Thành phố Hồ Chí Minh, Việt Nam",
    //   "phone": "19002224",
    //   "cityId": "599535ea-1ea2-4393-9b5a-3ba3a807f363",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/2019/12/27/hanh-lang-soat-ve---glx-linh-trung_1577419450118.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2019/12/27/sanh-rap-1---glx-linh-trung_1577419440598.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2023/11/22/1_1700637478799.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/11/22/2_1700637484818.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/11/22/3_1700637490340.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/11/22/4_1700637494798.jpg"
    //   ],
    //   "reward": true,
    //   "order": 18
    // },
    // {
    //   "id": "c8e91c27-3a18-4c0b-a9f3-c32f452ff8a8",
    //   "name": "Galaxy Nha Trang Center",
    //   "slug": "galaxy-nha-trang-center",
    //   "latitude": "12.248283599194691",
    //   "longitude": "109.19596412659256",
    //   "address": "Tầng 3, Trung Tâm Thương Mại Nha Trang Center - 20 Trần Phú, Nha Trang, Khánh Hòa",
    //   "phone": "19002224",
    //   "cityId": "34b721af-15ac-46f9-8d6b-54e86446d05f",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/2022/11/10/main-lobby-2_1668067947361.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2022/11/10/corridor1-3_1668067937479.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2023/11/22/1_1700635900247.JPG",
    //     "https://cdn.galaxycine.vn/media/2023/11/22/2_1700635909604.JPG",
    //     "https://cdn.galaxycine.vn/media/2023/11/22/3_1700635914194.JPG",
    //     "https://cdn.galaxycine.vn/media/2023/11/22/4_1700635918430.JPG"
    //   ],
    //   "reward": true,
    //   "order": 19
    // },
    // {
    //   "id": "fdb58508-f599-449b-a821-faa1bb8ad82a",
    //   "name": "Galaxy Trường Chinh",
    //   "slug": "galaxy-truong-chinh",
    //   "latitude": "10.819985526603515",
    //   "longitude": "106.63240165385879",
    //   "address": "Tầng 3 - Co.opMart TTTM Thắng Lợi - Số 2 Trường Chinh, Tây Thạnh, Tân Phú, Thành phố Hồ Chí Minh",
    //   "phone": "19002224",
    //   "cityId": "599535ea-1ea2-4393-9b5a-3ba3a807f363",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/2022/11/10/1-1_1668066011773.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2022/11/10/1-2_1668065994951.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2023/11/22/1_1700636429766.JPG",
    //     "https://cdn.galaxycine.vn/media/2023/11/22/2_1700636434005.JPG",
    //     "https://cdn.galaxycine.vn/media/2023/11/22/3_1700636438090.JPG",
    //     "https://cdn.galaxycine.vn/media/2023/11/22/4_1700636442680.JPG"
    //   ],
    //   "reward": false,
    //   "order": 20
    // },
    // {
    //   "id": "25e5e0cd-02ff-409d-80b6-1ee94d8a7b6b",
    //   "name": "Galaxy GO! Mall Bà Rịa",
    //   "slug": "galaxy-go-mall-ba-ria",
    //   "latitude": "10.49227419779594",
    //   "longitude": "107.1681739711613",
    //   "address": "Tầng 3 TTTM GO! Bà Rịa, Số 2A đường Nguyễn Đình Chiểu, KP1, P. Phước Hiệp, TP. Bà Rịa, Tỉnh Bà Rịa-Vũng Tàu",
    //   "phone": "19002224",
    //   "cityId": "ae597899-e73a-42f2-b8a7-1a0ac5f29057",
    //   "imageLandscape": "https://cdn.galaxycine.vn/media/2023/10/17/go-mall-5_1697516787722.jpg",
    //   "imagePortrait": "https://cdn.galaxycine.vn/media/2023/10/17/go-mall-1_1697516780223.jpg",
    //   "imageUrls": [
    //     "https://cdn.galaxycine.vn/media/2023/10/24/sanh-rap_1698118281139.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/24/hanh-lang_1698118290698.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/24/hanh-lang-rap_1698118297651.jpg",
    //     "https://cdn.galaxycine.vn/media/2023/10/24/phong-chieu_1698118302985.jpg"
    //   ],
    //   "reward": false,
    //   "order": 999999900
    // }
  ]
import React, { ReactNode } from "react";
import Header from "./Header";
import SectionTop from "./SectionTop";
import Form from "./Form";
import LuckyWheel from "./LuckyWheel";
import Rules from "./Rules";
import { isMobile } from "react-device-detect";
import Reward from "./Reward";
const Layout = () => {
  return (
    <div className="relative md:p-[50px] p-[10px]">
      <Header />
      <SectionTop />
      <Form />
      <Reward />
      <Rules />
      <div className="relative flex justify-center items-end md:py-5 pt-8 pb-5">
        <a
          href="https://dehub.tech/"
          target="_blank"
          rel="noreferrer"
          className="md:text-2xl text-lg text-white font-bold md:mr-5 mr-1"
        >
          dehub.tech
        </a>
        {/* <a
          href="https://www.facebook.com/galaxycinevn"
          target="_blank"
          rel="noreferrer"
          className="md:text-2xl text-lg text-white font-bold flex md:px-5 px-1 border-l-2 border-white"
        >
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            data-icon="square-facebook"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
            width="30"
            height="30"
            className="md:mr-4"
          >
            <path
              fill="currentColor"
              d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
            ></path>
          </svg>
          galaxycinema
        </a> */}
      </div>
    </div>
  );
};

export default Layout;

import React from "react";
import classNames from "classnames";
import Join from "./Join";
import Awards from "./Awards";
import Roles from "./Roles";
import { AnimatePresence, motion } from "framer-motion";
import { isMobile } from "react-device-detect";
const ListTab = [
  { id: 1, name: "Cách thức tham gia", key: "1" },
  { id: 2, name: "Giải thưởng", key: "2" },
  { id: 3, name: "Thể lệ", key: "3" },
];
export default function Rules() {
  const [active, setActive] = React.useState(1);
  return (
    <div className={isMobile ? "md:mt-[-130px] mt-[-130px]" : "md:mt-[-100px] mt-[-100px] "}>
      <div className="mb-5 relative">
        <ul className="list-none flex md:justify-center justify-evenly items-center md:gap-4 gap-2">
          {ListTab.map((item) => (
            <li
              className={classNames({
                "md:text-lg text-sm uppercase md:font-extrabold font-extrabold  cursor-pointer":
                  true,
                "underline scale-110 text-[#fadb14] px-3": active === item.id,
                " text-white scale-90": active !== item.id,
              })}
              key={item.key}
              onClick={() => setActive(item.id)}
            >
              {item.name}
            </li>
          ))}
        </ul>
      </div>
      <section className="relative mx-auto w-[800px] max-[800px]:w-full px-[20px] md:px-[0px] content">
        <AnimatePresence mode="wait">
          <motion.div
            key={active}
            initial={{ y: 10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: -10, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            {active === 1 && <Join />}
            {active === 2 && <Awards />}
            {active === 3 && <Roles />}
          </motion.div>
        </AnimatePresence>
      </section>
    </div>
  );
}

import React from "react";
import BillOnline from "../assets/images/bill-oneline.jpg";
import BillOffline from "../assets/images/bill-offline.jpg";
export default function Join() {
  return (
    <div className="text-left">
      {/* <h2 className="md:text-lg text-base text-white  uppercase font-extrabold text-center mb-4 mt-[20px]">
        <strong>
          <i>Quay số giải thưởng</i>
        </strong>
      </h2> */}
      <h2 className="md:text-lg text-base text-white  uppercase font-extrabold text-center">
        Cách thức tham gia
      </h2>
      <p>
        Khách hàng mua bất kỳ đơn hàng có giá trị hơn 250.000 VND sẽ đủ điều kiện tham gia chương
        trình. Các bước thực hiện như sau:
      </p>
      <ol className="list-decimal text-white text-base pl-[20px]">
        <li className="text-base">
          <p>
            Truy cập vào trang web chương trình{" "}
            <strong>
              <i>"Quốc Tế Thiếu Nhi"</i>
            </strong>{" "}
            -{" "}
            <a
              href="https://luckywheel.dehub.tech/"
              target="_blank"
              rel="noreferrer"
            >
              luckywheel.dehub.tech
            </a>
          </p>
        </li>
        <li>
          Nhập thông tin vào mẫu đăng ký:
          <article>
            <p>- Số điện thoại cá nhân.</p>
            <p>- Email cá nhân.</p>
            <p>
              - Mã giao dịch trên hoá đơn (là số đơn hàng của quý khách)
            </p>
            <p>- Cụm rạp giao dịch.</p>
          </article>
        </li>
        <li>
          Bấm{" "}
          <strong>
            <i>"Đăng ký"</i>
          </strong>{" "}
          để hoàn tất.
        </li>
      </ol>
      
      <h3 className="text-lg text-white  uppercase font-extrabold mt-[15px] text-center">
        Lưu ý
      </h3>
      <ol className="list-disc text-white text-base pl-[20px]">
        <li>
          01 số điện thoại có thể đăng ký tham gia với nhiều mã giao dịch khác
          nhau.
        </li>
        <li>
          Nhiều số điện thoại cùng đăng ký với 01 mã giao dịch (dù hợp lệ),
          Gét Gô chỉ tính số điện thoại đăng ký thông tin tại thời điểm
          đăng ký được ghi nhận sớm nhất là hợp lệ. Các số điện thoại trong cùng
          một mã giao dịch sau đó sẽ bị loại khỏi danh sách quay số may mắn.
        </li>
        <li>
          [Mã giao dịch/ mã đơn hàng] hợp lệ khớp với tên cụm rạm giao dịch, các thông
          tin này phải có ghi nhận trong hệ thống của Gét Gô.
        </li>
        <li>
          Mỗi mã giao dịch đều khác nhau, được phát sinh từ hệ
          thống của Gét Gô.
        </li>
      </ol>
    </div>
  );
}

import React from "react";
import Logo from "../assets/images/dehub-logo.png";
import Mascot from "../assets/images/mascot.png";
import { isMobile } from "react-device-detect";
import { motion } from "framer-motion";
import TetRongMobile from "../assets/images/tet-rong-mobile.png";
export default function Header() {
  return (
    <>
      {isMobile ? (
        <header className="relative flex items-center justify-between md:gap-5 gap-2">
          {/* <motion.a
            href="https://dehub.tech/"
            target="_blank"
            rel="noreferrer"
            className="md:text-2xl text-lg text-white font-bold md:mr-5 mr-1 "
            initial={{
              opacity: 0,
              x: -50,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: {
                duration: 1,
              },
            }}
            viewport={{ once: true }}
          >
            <img
              src={Logo}
              alt="Logo DeHub"
              className="w-[80px] md:w-[auto]"
            />
          </motion.a> */}
          <motion.img
            src={Mascot}
            alt="Logo Mascost"
            className="w-[100px] h-[100px] md:w-[100px] md:h-[100px]"
            initial={{
              opacity: 0,
              x: 0,
              y: 0
            }}
            whileInView={{
              opacity: 1,
              x: -5,
              y: -10,
              transition: {
                duration: 1,
              },
            }}
            viewport={{ once: true }}
          />
           {/* <motion.img
            src={TetRongMobile}
            alt="Logo Mobile TetRong"
            className="w-[120px] md:w-[300px]"
            initial={{
              opacity: 0,
              x: -50,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: {
                duration: 1,
              },
            }}
            viewport={{ once: true }}
          />*/}
        </header>
      ) : (
        <header className="relative flex items-center justify-between md:gap-5 gap-2">
          {/* <motion.a
            href="https://dehub.tech/"
            target="_blank"
            rel="noreferrer"
            className="md:text-2xl text-lg text-white font-bold md:mr-5 mr-1 logo-left flex-none md:w-[350px] md:max-[800px]:w-[50%] w-auto"
            initial={{
              opacity: 0,
              x: -50,
            }}
            whileInView={{
              opacity: 1,
              x: 0,
              transition: {
                duration: 1,
              },
            }}
            viewport={{ once: true }}
          >
            <img
              src={Logo}
              alt="Logo DeHub"
              className="md:h-[100px]"
            />
          </motion.a> */}
          <div className="logo-right flex-none max-[800px]:w-[50%] flex md:justify-between justify-end items-center md:gap-0 gap-2">
            <motion.img
              src={Mascot}
              alt="Logo Mascost"
              className="w-[50px] h-[50px] md:w-[150px] md:h-[auto]"
              initial={{
                opacity: 0,
                x: 0,
                y: 0,
              }}
              whileInView={{
                opacity: 1,
                x: 0,
                y: -15,
                transition: {
                  duration: 1,
                },
              }}
              viewport={{ once: true }}
            />
          </div>
        </header>
      )}
    </>
  );
}

import React, { useEffect } from "react";
import Register from "../assets/images/register.webp";
import PhaoHoaTrai from "../assets/images/phao-hoa-trai.webp";
import PhaoHoaPhai from "../assets/images/phao-hoa-phai.webp";
import DropdownSelect from "react-dropdown-select";
import { DataCinemas } from "../data/data";
import styled from "@emotion/styled";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios, { AxiosError } from "axios";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import WheelComponent from 'react-wheel-of-prizes'

const StyledSelect = styled(DropdownSelect)`
  outline: none;
  border-radius: 4px;
  background: #fff;
  .react-dropdown-select-input {
    font-size: 18px;
    margin-left: 0px;
    ::placeholder {
      color: #656667;
    }
  }
  & .react-dropdown-select-content {
    height: 1.3rem;
    overflow: hidden;
    display: inline-flex;
    text-overflow: ellipsis;
    width: 90%;
    flex-wrap: nowrap !important;
    height: 100%;
    align-items: center;
  }
  & .react-dropdown-select-dropdown {
    width: max-content;
    min-width: 100%;
    color: #000;
    border-radius: 4px;
    flex-wrap: nowrap;
    z-index: 600;
    right: 0px;
    left: auto;
  }
`;

const MySwal = withReactContent(Swal);

const failedText = "Chúc bạn may mắn lần sau";
const segments = [
  failedText,
  'Voucher Phim Galaxy',
  failedText,
  'Voucher Lucky Box',
  failedText,
  'Voucher Game Gét Gô'
]
const segColors = [
  '#EE4040',
  '#F0CF50',
  '#815CD1',
  '#3DA5E0',
  '#34A24F',
  '#F9AA1F',
  '#EC3F3F',
  '#FF9000'
]

export default function Form() {
  const [isSetUserData, setIsSetUserData] = React.useState(false);
  const [wonPrize, setWonPrize] = React.useState(null);
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [transaction, setTransaction] = React.useState("");
  const [cinemaId, setCinemaId] = React.useState<string>("fe18db21-cdee-44ba-90c3-f2e3ac6c8320");
  const [errors, setErrors] = React.useState<{
    phone: string;
    email: string;
    transaction: string;
    cinemaId: string;
    movieId: string;
  }>({
    phone: "",
    email: "",
    transaction: "",
    cinemaId: "",
    movieId: "",
  });
  const [defaultValue, setDefaultValue] = React.useState<any>([DataCinemas[0]]);
  
  React.useEffect(() => {
    onSubmit();
  }, [wonPrize]);

  const handleCinemaId = (values: any) => {
    setErrors({ ...errors, cinemaId: "" });
    setDefaultValue(values);
    const { name } = values && values.length > 0 ? values[0] : "Lỗi chọn";
    setCinemaId(name);
  };

  const handlePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({ ...errors, phone: "" });
    const value = e.target.value;
    setPhone(value);
  };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({ ...errors, email: "" });
    const value = e.target.value;
    setEmail(value);
  };

  const handleTransaction = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({ ...errors, transaction: "" });
    const value = e.target.value;
    setTransaction(value);
  };


  const onSetUserData = () => {
    let error = {
      phone: "",
      email: "",
      transaction: "",
      cinemaId: "",
      movieId: "",
    };
    if (!phone) {
      error = { ...error, phone: "Vui lòng nhập Số điện thoại" };
    }
    if (phone) {
      const phoneRegex =
        /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/;
      if (!phoneRegex.test(phone)) {
        error = { ...error, phone: "Số điện thoại không đúng định dạng!" };
      }
    }
    if(!email) {
      error = { ...error, email: "Vui lòng nhập Email" };
    }
    if (email) {
      const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailRegex.test(email)) {
        error = { ...error, email: "Email không đúng định dạng!" };
      }
    }
    if (!transaction) {
      error = {
        ...error,
        transaction: "Vui lòng nhập mã đơn hàng!",
      };
    }
    if (!cinemaId) {
      error = {
        ...error,
        cinemaId: "Vui lòng chọn rạp đã giao dịch!",
      };
    }
    if (error.phone || error.email || error.transaction || error.cinemaId) {
      setErrors({ ...error });
      return;
    }

    setIsSetUserData(true);
  }

  const onSubmit = async () => {
    if(!wonPrize) {
      return;
    }
    
    try {
      const response = await axios.post(
        "https://luckywheel.dehub.tech/api/event-form",
        {
          phone: phone,
          email: email,
          transactionNo: transaction,
          wonPrize: wonPrize,
          cinemaId: cinemaId
        }
      );
      if (response) {
        if (wonPrize != failedText) {
          MySwal.fire({
            icon: "success",
            title: "Xin chúc mừng bạn",
            text: `${wonPrize}`,
            position: "center",
            showClass: {
              popup: `
                swal-mobile
                animate__animated
                animate__fadeInUp
                animate__faster
              `,
            },
            hideClass: {
              popup: `
              swal-mobile
                animate__animated
                animate__fadeOutDown
                animate__faster
              `,
            },
          });
        }
        else {
          MySwal.fire({
            icon: "error",
            title: "Xin nhỗiii",
            text: `${wonPrize}`,
            position: "center",
            showClass: {
              popup: `
                swal-mobile
                animate__animated
                animate__fadeInUp
                animate__faster
              `,
            },
            hideClass: {
              popup: `
              swal-mobile
                animate__animated
                animate__fadeOutDown
                animate__faster
              `,
            },
          });
        }
      } else {

      }

      clearData();
    } catch (error: any) {
      MySwal.fire({
        icon: "error",
        title: "Quay thưởng thất bại",
        text: `Có thể bạn đã từng tham gia quay thưởng, nếu chưa vui lòng thử lại với thông tin đơn hàng của bạn.`,
        showClass: {
          popup: `
            animate__animated
            animate__fadeInUp
            animate__faster
          `,
        },
        hideClass: {
          popup: `
            animate__animated
            animate__fadeOutDown
            animate__faster
          `,
        },
      });

      clearData();
    }
  };

  const clearData = () => {
    setPhone("");
    setEmail("");
    setTransaction("");
    setDefaultValue([]);
  };

  const onFinished = (winner: any) => {
    console.log("winner", winner);
    setWonPrize(winner);
  }

  if(isSetUserData) {
    return (<section className={isMobile ? "wheel-mobile relative mx-auto w-[700px] max-[800px]:w-full max-h-[600px] mt-[-100px] ml-[-20px]" : "relative mx-auto w-[570px] max-[570px]:w-full max-h-[600px] mt-[-120px] mb-[170px]"}>
          <WheelComponent
            segments={segments}
            segColors={segColors}
            onFinished={(winner: any) => onFinished(winner)}
            primaryColor='black'
            contrastColor='white'
            buttonText='Quay Ngay'
            isOnlyOnce={true}
            size={isMobile ? 250 : 290}
            upDuration={100}
            downDuration={1000}
            fontFamily='Arial'
            fontSize="10px"
          />
    </section>)
  }

  return (
    <section className={isMobile ? "relative mx-auto w-[650px] max-[800px]:w-full md:mt-[0px] mt-[20px] ml-[-10px]" : "relative mx-auto w-[700px] max-[800px]:w-full md:mt-[0px] mt-[20px]"}>
      <motion.div
        className="absolute min-[1300px]:-left-[40%] min-[996px]:-left-[10%] -left-[0%] -top-[20%] max-[600px]:-top-[10%]"
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 1,
          },
        }}
        animate={{ scale: [0, 0, 1.4, 1.2, 1.1, 1] }}
        viewport={{ once: true }}
      >
        <img
          src={PhaoHoaPhai}
          alt="Phao hoa trai"
          className="md:w-full w-[70px]"
        />
      </motion.div>
      <motion.div
        className="absolute min-[1300px]:-right-[40%]  min-[996px]:-right-[10%] -right-[0%]  -top-[20%] max-[600px]:-top-[10%]"
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          transition: {
            duration: 1,
          },
        }}
        animate={{ scale: [0, 0, 1.4, 1.2, 1.1, 1] }}
        viewport={{ once: true }}
      >
        <img
          src={PhaoHoaTrai}
          alt="Phao hoa trai"
          className="md:w-full w-[70px]"
        />
      </motion.div>
      <motion.h3
        className="text-center font-black text-white uppercase md:text-5xl text-2xl mb-[20px] md:mt-[10px]"
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          y: isMobile ? -130 : -150,
          transition: {
            duration: 1,
          },
        }}
        animate={{ scale: [0, 0, 1.4, 1.2, 1.1, 1] }}
        viewport={{ once: true }}
      >
        Nhập thông tin để quay thưởng
      </motion.h3>
       <motion.div
        className="w-full"
        initial={{
          opacity: 0,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
          y: -140,
          transition: {
            duration: 1,
          },
        }}
        animate={{ scale: [0, 0, 1.4, 1.2, 1.1, 1] }}
        viewport={{ once: true }}
      >
       <form className={isMobile ? "form-mobile flex flex-col" : "flex flex-col"}>
        <motion.input
          value={phone}
          name="phone"
          onChange={handlePhone}
          type="text"
          placeholder="Vui lòng nhập SĐT*:"
          className="w-full h-[40px] px-[5px]  placeholder:text-gray-500 md:text-lg text-base md:font-normal rounded"
          initial={{
            opacity: 0,
            y: -50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        />
        {errors.phone && (
          <p className="text-red-500 text-sm text-left mt-1">{errors.phone}</p>
        )}
        <motion.input
          value={email}
          name="email"
          onChange={handleEmail}
          type="text"
          placeholder="Vui lòng nhập Email*:"
          className="w-full h-[40px] px-[5px]  placeholder:text-gray-500 md:text-lg text-base md:font-normal rounded"
          initial={{
            opacity: 0,
            y: -50,
          }}
          whileInView={{
            opacity: 1,
            y: 10,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        />
        {errors.email && (
          <p className="text-red-500 text-sm text-left mt-[12px]">{errors.email}</p>
        )}
        <motion.input
          name="transaction"
          value={transaction}
          onChange={handleTransaction}
          type="text"
          placeholder="Vui lòng nhập mã Đơn hàng trên hoá đơn*:"
          className="h-[40px] mt-[20px] w-full px-[5px] placeholder:text-gray-500 md:text-lg text-base md:font-normal rounded"
          initial={{
            opacity: 0,
            x: 50,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        />
        {errors.transaction && (
          <p className="text-red-500 text-sm text-left mt-1">
            {errors.transaction}
          </p>
        )}
        <motion.div
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: -10,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          <StyledSelect
            options={DataCinemas}
            values={defaultValue}
            onChange={handleCinemaId}
            searchable={false}
            dropdownPosition="auto"
            valueField="id"
            labelField="name"
            placeholder="Vui lòng chọn rạp đã giao dịch*:"
            className="w-full h-[40px] mt-[20px] md:text-lg text-base md:font-normal rounded text-left placeholder:text-gray-500"
          />
          {errors.cinemaId && (
            <p className="text-red-500 text-sm text-left mt-1">
              {errors.cinemaId}
            </p>
          )}
        </motion.div>
        <motion.div
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: {
              duration: 1,
            },
          }}
          viewport={{ once: true }}
        >
          {errors.cinemaId && (
            <p className="text-red-500 text-sm text-left mt-1">
              {errors.cinemaId}
            </p>
          )}
        </motion.div>
        <p className="md:text-lg text-base text-white mt-5 md:mb-10 mb-5 text-center">
          (*) Bằng cách chọn <strong className="uppercase">Đăng ký</strong>, tôi
          xác nhận đã đọc và hiểu các thể lệ của chương trình.
        </p>
        <motion.button
          className="text-center mx-auto"
          type="button"
          onClick={onSetUserData}
          initial={{
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: {
              duration: 1,
            },
          }}
          animate={{ scale: [0, 0, 1.4, 1.2, 1.1, 1] }}
          viewport={{ once: true }}
        >
          <img src={Register} alt="Logo Pepsi" className="w-[200px]" />
        </motion.button>
      </form>
      </motion.div>
    </section>
  );
}

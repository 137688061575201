import React from "react";
import TVSamsung from "../assets/images/tv.png";
import Giai1 from "../assets/images/voucher.png";
import Giai2 from "../assets/images/luckybox.png";
import Giai3 from "../assets/images/game.png";
import { motion, Variants } from "framer-motion";

const cardVariants: Variants = {
  offscreen: {
    y: 30,
  },
  onscreen: {
    y: 0,
    rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

const rewardVariants: Variants = {
  offscreen: {
    opacity: 0,
    x: 100,
  },
  onscreen: {
    x: 0,
    rotate: -10,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

export default function Awards() {
  return (
    <>
      <motion.div
        className="my-[15px]"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
      >
        <h2 className="md:text-lg text-base text-white  uppercase font-extrabold my-5 text-center">
          Điều kiện & điều khoản các giải thưởng
        </h2>
        
      </motion.div>
      <motion.div
        className="my-[15px] relative"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
      >
        <h2 className="md:text-lg text-base text-white  uppercase font-extrabold text-center">
          Các giải quay số trúng thưởng
        </h2>
        <p className="text-base text-white text-left  uppercase font-extrabold mt-3">
          <strong>
            <i>Voucher Xem phim Galaxy Miễn Phí</i>
          </strong>
        </p>
        <motion.div
          className="absolute -right-[0%] -top-[5%] z-50 md:block hidden"
          variants={rewardVariants}
          whileInView={{
            opacity: 1,
            x: 0,
            y: 30,
            transition: {
              duration: 1,
            },
          }}
        >
          <img
            src={Giai1}
            alt="Giải Nhất"
            className="md:w-[120px] w-[80px] text-center mx-auto"
          />
        </motion.div>
        <ul className="list-disc text-white text-base pl-[20px]">
          <li>Giải thưởng gồm 1 voucher ưu đãi miễn phí vé xem phim 2D.</li>
          <li>
            Thời hạn sử dụng: đến hết ngày 03/09/2024.
          </li>
          <li>
            Áp dụng cho khách hàng thành viên Gét Gô nhận được giải
            thưởng.
          </li>
          <li>
            Vé ưu đãi áp dụng tại các rạp Galaxy trên toàn quốc; Không áp dụng
            tại rạp Galaxy Sala hoặc các rạp mới mở trong năm 2024-2025.
          </li>
          <li>
            Ưu đãi không có giá trị chuyển nhượng, không hoàn lại hay quy đổi
            thành tiền mặt.
          </li>
          <li>
            Không áp dụng đồng thời cho ưu đãi khác, suất chiếu sớm, sneak show,
            suất chiếu đặc biệt, phim 3D, phòng chiếu đặc biệt.
          </li>
          <li>
            Ưu đãi có thể kết thúc khi đã hết hạn mà không cần phải thông báo.
          </li>
          <li>
            Gét Gô không giải quyết gia hạn sử dụng ưu đãi trong trường
            hợp quá hạn sử dụng mà khách hàng không sử dụng ưu đãi.
          </li>
          <li>
            Trong mọi trường hợp, quyết định của Gét Gô là quyết định
            cuối cùng.
          </li>
        </ul>
      </motion.div>
      <motion.div
        className="my-[15px] relative"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
      >
        <p className="md:text-lg text-base text-white text-left  uppercase font-extrabold mt-3">
          <strong>
            <i>Voucher Lucky Box</i>
          </strong>
        </p>
        <motion.div
          className="absolute -right-[0%] -top-[22%] z-50 md:block hidden"
          variants={cardVariants}
          whileInView={{
            opacity: 1,
            x: 0,
            y: 70,
            transition: {
              duration: 1,
            },
          }}
        >
          <img
            src={Giai2}
            alt="Giải Nhì"
            className="md:w-[100px] w-[80px] text-center mx-auto"
          />
        </motion.div>
        <ul className="list-disc text-white text-base pl-[20px]">
          <li>
            Giải thưởng gồm 1 lần chơi tại máy Gét Gô Lucky Box.
          </li>
          <li>
            Thời hạn sử dụng: 6 tháng sau khi nhận Giải quay.
          </li>
          <li>
            Áp dụng cho khách hàng Gét Gô nhận được giải
            thưởng.
          </li>
          <li>
            <strong>Voucher Luckybox</strong> sẽ được quy đổi tại Gét Gô sau 03 (ba) ngày kể từ khi quý khách mua hàng.
            Quý khách vui lòng xuất trình email/SĐT khi nhận giải.
          </li>
          <li>
            Ưu đãi không có giá trị chuyển nhượng, không hoàn lại hay quy đổi
            thành tiền mặt.
          </li>
          <li>
            Ưu đãi có thể kết thúc khi đã hết hạn mà không cần phải thông báo.
          </li>
          <li>
            Gét Gô không giải quyết gia hạn sử dụng ưu đãi trong trường hợp quá hạn sử dụng mà khách hàng không sử dụng ưu đãi.
          </li>
          <li>
            Trong mọi trường hợp, quyết định của Gét Gô là quyết định
            cuối cùng.
          </li>
        </ul>
      </motion.div>
      <motion.div
        className="my-[15px] relative"
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
      >
        <p className="text-base text-white text-left  uppercase font-extrabold mt-3">
          <strong>
            <i>Voucher Máy chơi Game tại hệ thống Gét Gô</i>
          </strong>
        </p>
        <motion.div
          className="absolute -right-[2%] top-[22%] z-50 md:block hidden"
          variants={rewardVariants}
          whileInView={{
            opacity: 1,
            x: 0,
            y: -20,
            transition: {
              duration: 1,
            },
          }}
        >
          <img
            src={Giai3}
            alt="Giải Ba"
            className="md:w-[120px] w-[80px] text-center mx-auto"
          />
        </motion.div>
        <ul className="list-disc text-white text-base pl-[20px]">
          <li>
            Giải thưởng gồm 1 voucher ưu đãi miễn phí 1 lần chơi chơi Game tại hệ thống máy Gét Gô (trị giá 50.000 VND), được gửi qua email đăng ký sau ngày 15/06/2024.
          </li>
          <li>
            Thời hạn sử dụng: 6 tháng sau khi nhận Giải quay.
          </li>
          <li>
            Áp dụng cho khách hàng Gét Gô nhận được giải
            thưởng.
          </li>
          <li>
            Ưu đãi không có giá trị chuyển nhượng, không hoàn lại hay quy đổi
            thành tiền mặt.
          </li>
          <li>
            Ưu đãi có thể kết thúc khi đã hết hạn mà không cần phải thông báo.
          </li>
          <li>
            Gét Gô không giải quyết gia hạn sử dụng ưu đãi trong trường
            hợp quá hạn sử dụng mà khách hàng không sử dụng ưu đãi.
          </li>
          <li>
            Trong mọi trường hợp, quyết định của Gét Gô là quyết định
            cuối cùng.
          </li>
        </ul>
      </motion.div>
    </>
  );
}

import React from "react";
import HeaderBlock from "../assets/images/header-block.png";
// import Date from "../assets/images/date.webp";
import { isMobile } from "react-device-detect";
import { motion } from "framer-motion";

export default function SectionTop() {
  return (
    <>
      {isMobile ? (
        <section className="relative flex items-center justify-center md:mt-5 mt-2">
          <div>
            <motion.img
              src={HeaderBlock}
              alt="Quốc Tế Thiếu Nhi"
              className="w-full"
              initial={{
                opacity: 0,
                x: 0,
                y: 0,
              }}
              whileInView={{
                opacity: 1,
                x: 25,
                y: -70,
                transition: {
                  duration: 1,
                },
              }}
              viewport={{ once: true }}
            />
          </div>
        </section>
      ) : (
        <section className="relative flex items-center justify-center">
          <div>
            <motion.img
              src={HeaderBlock}
              alt="Quốc Tế Thiếu Nhi"
              className="w-[400px]"
              initial={{
                opacity: 0,
                x: 0,
                y: 0,
              }}
              whileInView={{
                opacity: 1,
                x: 45,
                y: -120,
                transition: {
                  duration: 1,
                },
              }}
              viewport={{ once: true }}
            />
          </div>
        </section>
      )}
    </>
  );
}
